import React from "react";
import { Link } from "gatsby";

import "../scss/molecules/_footer.scss";

const Header = () => (
  <>
    {" "}
    <footer id="footer" className="bg-color3 pb-0">
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-6 col-md-push-6 text-right">
            <ul className="soc-list">
              <li>
                <a href="https://twitter.com/PizzaAttorno" target="_blank">
                  <i className="icon icon-twitter2" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/attorno.nl" target="_blank">
                  <i className="icon icon-facebook2" />
                </a>
              </li>
              <li>
                <a href="https://www.pinterest.com/attorno0003/" target="_blank">
                  <i className="icon icon-pinterest" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/attorno_pizzakraam/"
                  target="_blank"
                >
                  <i className="icon icon-instagram" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-md-pull-6">
            <img
              className="logo"
              src="/design/img/logo-footer.png"
              alt="Attorno logo, mobiele pizzabus"
              height="50"
            />
            <span className="editContent">
              © 2019
              <br />
              <a href="https://www.thebrownfox.be/" target="_blank">
                Design by The Brown Fox{" "}
                <img
                  src="https://www.thebrownfox.be/design/img/logo/logo-thebrownfox-black.svg"
                  height="18"
                  alt="Logo the brown fox, your freelance web designer"
                />
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="footer-copy bg-color1 dark-bg py-1">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <Link to="/contacteer-ons">Contacteer ons</Link>
              <Link to="/gegevensbescherming">Gegevensbescherming</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
);

export default Header;
