import React from "react";
import { Link } from "gatsby";

export default class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownClass: "",
      open: false,
    };
  }
  toggle() {
    this.setState({
      dropdownClass: this.state.dropdownClass.length === 0 ? "open" : "",
    });
  }
  toggleMenu() {
    this.setState({ open: !this.state.open });
  }
  render() {
    return (
      <nav className="navbar bg-color1 dark-bg">
        <div className="container">
          <Link to="/" className="navbar-brand goto">
            <img
              src="/design/img/logo.png"
              className="image"
              alt="Attorno logo"
            />
          </Link>
          <button
            className="round-toggle navbar-toggle menu-collapse-btn collapsed"
            onClick={() => {
              this.toggleMenu();
            }}
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <div
            className={
              "navbar-collapse navMenuCollapse" +
              (this.state.open ? "" : " collapse")
            }
          >
            <ul className="nav">
              <li>
                <Link to="/waarom-attorno" className="nav-link">
                  Waarom attorno
                </Link>
              </li>
              <li>
                <Link to="/foodtruck-feesten" className="nav-link">
                  Feest organiseren
                </Link>
              </li>
              <li>
                <Link to="/bedrijfsfeesten" className="nav-link">
                  Bedrijfsfeesten
                </Link>
              </li>
              <li>
                <Link to="/mobiele-pizzabus" className="nav-link">
                  Mobiele pizzabus
                </Link>
              </li>
              <li>
                <Link to="/media" className="nav-link">
                  Foto's
                </Link>
              </li>
              <li className={"dropdown " + this.state.dropdownClass}>
                <a
                  className="dropdown-toggle nav-link"
                  id="menu-dropdown-more"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.toggle()}
                >
                  Meer..
                  <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/faq" className="dropdown-item">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to="/recensies" className="dropdown-item">
                      Recensies
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contacteer-ons" className="nav-link btn btn-primary">
                  Contacteer ons
                  <i className="icon-paper-plane" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
